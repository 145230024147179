import React from 'react'

import Mid from './Mid'
import Mid1 from './Mid1'
import Mid3 from './Mid3'
function Home() {
  return (
    <>
    <Mid />
    <Mid1 />
    <Mid3 />
    </>
  )
}

export default Home