import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import coal1 from '../Assets/coal1.jpg';
import coal2 from '../Assets/coal2.jpg';

function Mid() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const getBackgroundImage = () => {
    return currentSlide === 0 ? coal2 : coal1;
  };

  const sliderStyle = {
    backgroundImage: `url(${getBackgroundImage()})`, // Set the dynamic background image
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  return (
    <div style={sliderStyle} className="bg-gray-700 bg-blend-multiply pt-4 font-nav mt-8">
      <Slider {...settings}>
        <div className="relative h-screen">
          <div className="absolute inset-0 bg-gray-700 bg-opacity-50"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="text-center text-white">
              <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl">
                Pure Essence. 100% Coconut Charcoal
              </h1>
              <p className="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-6 lg:px-16">
                Here at CocoBrigs, we focus on 100% natural coconut shells without any additives or chemicals, ensuring a clean and pure grilling experience.
              </p>
              <div className="flex justify-center">
                <a href="/about" className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400">
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="relative h-screen">
          <div className="absolute inset-0 bg-gray-700 bg-opacity-50"></div>
          <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center text-white">
          <div className="text-center text-white">
              <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-5xl">
              Embrace Purity, Ignite Flavor: Unleash the Power of Coconut Charcoal!
              </h1>
              <p className="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-6 lg:px-16">
              Here at Cocobrigs, Where Nature Sparks Flavor - Illuminate Your Grilling Experience with Pure Coconut Charcoal Bliss!
              </p>
              <div className="flex justify-center">
                <a href="/about" className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400">
                  Learn more
                </a>
              </div>
            </div>
          </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default Mid;